// .animation-rectangle {
//    position: absolute;
  
//    z-index: 5555;
//    right: -610px;
//    top: 0;

//    & svg {


//     opacity: 2;
//     z-index: 3333;
//     & rect {
    
//         width: 954px;
     
//     }
//   }

  
// }

.animation-rectangle{
    position: absolute;
    width: 687px;
    height: 261px;
    top: 300px;
    left:1000px;
  overflow: hidden;
    //  background-color: white;
    border-radius: 50%;
    box-shadow: 13px 13px rgb(0, 0, 0), -13px -13px rgb(0, 0, 0);
      background: linear-gradient(161.26deg, #000000 100%, rgba(0, 0, 0, 105) 100%);

    rotate: (324deg);

}

.anim-rect{
  overflow: hidden;
    position: absolute;
    width: 1000px;
    height: 1000px;
    top: 0;
    left: 0;   

}
