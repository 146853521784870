.logo {
   // width: 244px;
   margin-top: 92px;
   display: block;
   margin-left: auto;
   margin-right: auto;
}

.logo-section {
   margin-bottom: 90px;
   overflow: hidden;
}

.logo-section img {
   margin-bottom: 50px;
}

.main-img {
   margin-top: -140px;
}

.text-main {
   /* font-weight: 400; */
   font-size: 38px;
   line-height: 100%;
   /* or 38px */

   text-align: center;
   overflow: hidden;
   color: #ffffff;
   /* margin-top: 50px;
   margin-bottom: 114px; */
}

.text-main-mobile {
   font-family: 'Neue Machina';
   font-style: normal;
   /* font-weight: 400; */
   font-size: 38px;
   line-height: 100%;
   /* or 38px */
   overflow: hidden;
   text-align: center;

   color: #ffffff;
   /* margin-top: 50px;
   margin-bottom: 114px; */
   display: none;
}

/* .main-img-main {
   z-index: 200;
   position: absolute;
}

.main-img-dop{
   margin-top: 45px;
   margin-left: 70px;
   height: 565px;
   width: 583px;
   z-index: 10;
} */

.text-second {
   /* width: 761px; */
   height: 64px;
   left: 579px;
   top: 123px;

   margin-top: 120px;

   font-family: 'Neue Machina';
   font-style: normal;
   font-weight: 700;
   font-size: 64px;
   line-height: 100%;
   /* identical to box height, or 64px */

   text-align: center;

   color: #ffffff;
}

.bottom-part {
   display: flex;
   justify-content: space-between;
   margin-top: 146px;

}

.left {
   display: flex;
   flex-direction: column;
}

.left-side {
   display: flex;
   flex-direction: row;

   /* height: 111px; */
   /* width: 79px; */
}

.left-side-img-left {
   max-height: 80px;

   object-fit: cover;
}
.left-side-img-right {
   max-height: 80px;
   /* max-width: 79px; */
   object-fit: cover;
}
.socials {
   text-align: center;
   vertical-align: middle;
   line-height: normal;
   height: auto;
   cursor: pointer;
   margin-left: -8px;
   wrap: nowrap;
   z-index: 9000;
}

.socials a {
   color: inherit;
   cursor: pointer;
   outline: none;
   text-decoration: none;
   padding-left: 8px;
   padding-right: 8px;
   font-family: 'HelveticaNeueCyr';
   line-height: 28px;
   font-size: 14px;
}

.socials-main {
   justify-content: space-between;
   display: flex;

   flex-direction: row;
   margin-top: 79px;
   margin-bottom: 55px;
}

.copywrite {
   font-family: 'HelveticaNeueCyr';
font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 27px;
   /* identical to box height, or 193% */
   padding-left: 66px;
   display: flex;

   color: #787878;
}
.form {
   width: 100%;
}

.right-side {
   text-align: left;
   width: 285px;
}

.right-side p {
   width: 314px;
   height: 63px;
   font-family: 'HelveticaNeueCyr';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 21px;
   /* or 21px */

   text-align: left;
   display: flex;
   align-items: center;
}
.right-side span {
   font-size: 14px;
   margin-top: 25px;
}

.form-input-button {
   border: 0px solid #1e56e1;

   justify-content: space-between;
   background-color: inherit;

   width: 100%;
   display: flex;
   flex-wrap: wrap;
   margin-bottom: 100px;
}
.form-input-button-border {
   border-bottom: 0px solid #1e56e1;

   & button {
      position: relative;
      & svg {
         position: absolute;
         left: 280px;
         top: -40px;
         cursor: pointer;
      }
   }
}

.right-side input {
   color: #787878;
   border: 0;
   border-bottom: 1px solid #1e56e1;

   /* border-bottom: 1px solid #1e56e1; */

   height: 40px;
   width: 305px;
   background-color: inherit;

   /* background-image: url(Input-image.png); */
   background-position: right 0% bottom 50%;
   background-repeat: no-repeat;
}
.right-side input:focus {
   outline: none;
}

.right-side button {
   background-color: inherit;
   border: 0;
   position: relative;
   /* background-image: url(Input-image.png); */
   background-position: right 0% bottom 50%;
   background-repeat: no-repeat;

   margin-top: -12px;
}

.registered-mail {
   color: #ffb40a;
   display: block;
   margin: 0 auto;
   text-align: center;
}

.privacy-policy {
   width: 227px;

   margin: 24px 0 57px auto;
   font-family: 'HelveticaNeueCyr';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 27px;
   display: flex;
   flex-direction: row;
   align-items: center;
   text-align: right;
   color: #FFE3E3;

   cursor: pointer;

}
.container{
   width: 70%;
}

@media (max-width: 1185px) {
   .bottom-part {
      margin: 146px 50px auto 30px;
   }
}
@media (max-width: 800px) {
   .bottom-part {
      margin: 146px 30px auto 20px;
   }
}

@media (max-width: 750px) {
   .bottom-part {
      flex-direction: column;
      align-items: center;
   }
   .right-side {
      margin-bottom: 100px;
   }
}

@media (max-width: 750px) {
   /* .logo img{
      width: 188px;
      height: 93px;
      
   } */

   .main-img {
      margin: -50px auto 0 auto;
      width: 50%;
   }
   .text-second {
      margin: 50px auto 0 auto;
   }
   .text-main {
      display: none;
   }

   .text-main-mobile {
      font-size: 30px;
      display: block;
      overflow: hidden;
   }
   .bottom-part {
      margin-top: 50px;
   }
   /* .main-img {
        margin-left: -37px;
        margin-right: auto;
      width: 130%;
    } */

   .text-second {
      font-size: 38px;
      margin-top: 50px;
      padding-bottom: 40px;
      overflow: hidden;
   }

   .socials {
      margin-bottom: 60px;
      & a {
         font-size: 12px;
      }
   }

   .socials-main {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
   }
   .copywrite {
      padding: 0;
      display: block;
      margin: 0 auto 50px auto;
   }


   .bottom-part {
      display: flex;
      flex-direction: column-reverse;
      margin: 0;
      & .left-side {
         margin-top: -40px;
      }
   }

   .left-side {
      width: 290 px;
      margin: auto;
   }

   .right-side p {
      margin-top: 50px;
      width: inherit;
   }
   .form-input-button-border {
      & input {
         width: 100%;
         
      }
      & button   {
 
         margin-left: -20px;
        
      }
   }
   #form {
      width: inherit;
   }
}
