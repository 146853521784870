.mvp-third-part__main-text {
   font-size: 35px;
}

.mvp-third-part-descr {
   font-style: normal;
   font-weight: 400;
   font-size: 17px;
   line-height: 20px;
}

.mvp-third-part__three-things {
   font-style: normal;
   font-weight: 400;
   font-size: 37px;
   line-height: 40px;
}

.mvp-third-part__main-image {
   // transform: scale(90%);
}

.mvp-third-part__things-item {
   font-family: 'Neue Machina';
   font-size: 1.7vw;
   font-style: normal;
   font-weight: 400;
   line-height: 150%;
   & p {
      color: #1e56e1;
      padding-right: 8px;
   }
}
.mvp-third-part__things-bottom-text {
   font-size: 2.1vw;
}

.mvp-third-part__join-button {
   z-index: 10;
   & button {
      background: #3e54fc;
      width: 200px;
   }

   & span {
      font-size: 15px;
      color: white;
      text-transform: uppercase;
      font-family: 'Neue Machina';
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
   }
}

.mvp-third__what-weshare {
   transform: scale(80%);
}

.mvp-third-part__three-things {
   color: #fff;
   text-align: center;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: 'Neue Machina';
   font-size: 60px;
   font-style: normal;
   font-weight: 400;
   line-height: 100%;
}

@media (max-width: 1024px) {
   .mvp-third__what-weshare {
      // margin-top: 0px !important;
      margin-bottom: 100px !important;
   }
   .mvp-third-part__join-button{
      margin-bottom: 40px;
   }
}

@media (max-width: 760px) {
   .mvp-third-part__join-button{
      margin-bottom: 0;
   }
   .mvp-third-part__main-text {
      padding-top: 50px !important;
      font-size: 25px;
      font-weight: 700;
   }
   .mvp-third-part-descr {
      display: flex !important;
      font-size: 14px !important;
      line-height: 18px !important;
      font-family: 'HelveticaNeueCyr';
      align-items: center;
      text-align: center;
      font-weight: 200;
      width: 90% !important;
      display: flex !important;
      margin: auto !important;
      margin-top: 25px !important;
   }
   .mvp-third-part__join-button {
      & .button_container {
         width: auto;
      }
   }
  
   .mvp-third-part__three-things {
      margin-top: -150px;

      margin-bottom: 50px;
      font-size: 25px !important;
      line-height: 25px !important;
      font-weight: 700;
   }
   .mvp-third-part__things-item {
      margin-top: 10px;
      width: 90% !important;
      font-size: 21px !important;
      line-height: 25px !important;
      text-align: left !important;
      font-weight: 200;

      & p {
         font-size: 35px !important;
         margin-top: 3px;
      }
   }
   .mvp-third-part__things-bottom-text {
      display: flex;
      margin: auto;
      text-align: center;
      align-items: center;
      font-size: 30px;
      line-height: 31px;
      margin-top: 50px;
      font-weight: 700;
   }
   .mvp-third-part__main-image-wrapper {
      width: 100% !important;
   }
   .mvp-third-part__main-image {
      transform: scale(100%);
      padding-bottom: 70px !important;
      padding-right: 0 !important;
   }
   .mvp-third-part__roadmap-title {
      margin-top: 0px;
   }
}
