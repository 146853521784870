.blob-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
   height: 100px;

  }

  .blob-three{
    position: absolute;
    width: 300px;
    height: 300px;
    left: -120px;
    
    // background: #fff
  }