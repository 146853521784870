.blob-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
   height: 100px;

  }

  .blob-two{
    position: absolute;
    width: 500px;
    height: 500px;
    top: 2rem;
    right: -4rem;
    
    // background: #fff
  }