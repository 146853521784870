
.thirdCellMainImgp1, .thirdCellMainImgp2{
   display: none;
}

.third-part__top-text {
   display: block;
   margin: 65px auto 0 auto;
   max-width: 1112px;
   max-height: 192px;
   width: auto;

   & p {
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 100%;
      
      /* or 64px */

      text-align: center;
   }
}

.third-part__flex-row {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   margin-top: 87px;
   margin-left: auto;
   margin-right: auto;
   justify-content: center;

}

.third-part__flex-row__cell{
   position: relative;
      width: 553px;
     height: 713px;
     text-align: left;
    border: 1px solid #373737;

      & .third-part__image-main-img{
         position: relative;
         z-index: 8888;   
      }
      & .third-part__image-dop1{
         position: absolute;
         left: 90px;
         top: 210px;
         z-index: 7777;
       }

       & .third-part__image-dop2{
         position: absolute;
         left: 375px;
         top: 410px;
       }


       & .third-part__image-dop3{
         position: absolute;
         left: 75px;
         top: 400px;
       }
    
   

 
}

.cell_container {
    margin: 194px 0 0 84px;
}

.cell__number-cell {
   margin-top: 194px;
   font-style: normal;
   font-weight: 300;
   font-size: 75px;
   line-height: 100%;
}

.cell__name-cell {
   margin-top: 23px;
   font-weight: 800;
   font-size: 40px;
   line-height: 100%;
}

.cell__list {
   font-family: 'HelveticaNeueCyr';
    margin-top: 49px;
     padding-left: 15px;
    padding-bottom: 168px;
    padding-right: 136px;
   font-weight: 400;
   font-size: 18px;
   line-height: 150%;
   & ul {
      padding: 0;
   }
}


.second-cell__img-one{
    position: relative;
   margin-top: 10px;
   top: 343px;
   // left: 20px;
   z-index:6666;
   & svg{
      margin-left: 20px;
   }
}

.second-cell__img-two{
    position: relative;
   margin-top: -300px;
   margin-left: -10px;
   // left: 270px;
   top: 130px;
   z-index: 6666;
   & svg{
      margin-left: 270px;
   }
}

.second-cell__img-dop-one{
   position: absolute;
   left: 285px;
   top: 193px;
   z-index: 3333;
}

.second-cell__img-dop-two{
   position: absolute;
   left: 60px;
   bottom: 90px;
}

.third-cell{
   margin-top: 159px;
   margin-left: 32px;
}


.third-cell__title{
   margin-left: -15px;
}

.second-cell__image-three {
   margin-top: 178px;
   margin-left: 18px;
   & img {
      width: 522px;
      height: 368px;
   }

}


.third-part__six-cell{

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: auto;
   height: 100%;
   width: 100%;

   & .third-part__six-cell-main-img{
      position: relative;
      z-index: 8888;

   }
   
   & .third-part__six-cell-dop1{
      position: absolute;
      left:215px;
      top: 202px;
      z-index: 6666;
   
   }
   & .third-part__six-cell-dop2{
      position: absolute;
      left: 110px;
      top: 252px;
      z-index: 6666;
   }
   & .third-part__six-cell-dop3{
      position: absolute;
      top: 230px;
      left: 405px;
      z-index: 6666;
      
   }
   & .third-part__six-cell-dop4{
      position: absolute;
      z-index: 6666;
      top: 520px;
      left: 310px;
      z-index: 6666;
   }
   & .third-part__six-cell-dop5{
      position: absolute;
      top: 330px;
      left: 50px;
      z-index: 6666;
      
   }
}



