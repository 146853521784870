.App {
   text-align: center;
}

.font-face-gm {
   font-family: 'Neue Machina';
}

@media (max-width: 1105px) {
   .first-page__second-part {
      // width: 100%;
   }

   .signup-page__signup-cell-border {
      display: none;
   }
   .signup-page__signup-inputs-container input {
      // width: 380px;
   }
   .date-time-container input {
      // width: 170px;
   }
   #list-casual {
      // width: 380px;
   }
   .inputs-container__signup-button {
      // width: 380px;
   }
}

@media (max-width: 1060px) {
   .center-header .center-buttonz {
      gap: 5px;
   }
   .container-road-map {
      width: auto;
   }
}

@media (width: 1000px) {
   .second-part__top-text-top {
      width: 325px;
   }

   .signup-page__signup-container {
      display: flex;
      flex-direction: column;
      width: 500px;
   }
}

@media (max-width: 750px) {
#loader{
   left: 19%;
}

   .header {
      max-width: 375px;
      width: 100vh;

      margin: auto;
      right: 0;
      & img {
         margin-left: 24px;
      }
      & .header-wrapper {
         right: -10px;
      }
   }
   .center-header {
      display: none;
   }
   .sign-up-button,
   .header-right-side .button_container {
      display: none;
   }
   .first-page__central-button .button_container {
      margin: 120px auto 40px auto;
   }
   .next-label {
      width: 218px;
      font-size: 13px;
   }

   .header-right-side {
      margin-right: 15px;
   }
   .right-label-counter .time-left span p {
      margin-left: 3px;
   }
   .time-left {
      & p {
         font-size: 18px;
         line-height: 20px;
         text-align: right;
      }
      & span {
         justify-content: right;
      }
   }

   .first-page__main-container {
      justify-content: center;
      margin-left: 15%;
      margin-top: 150px;
      height: auto;

      & p {
         max-width: 20px;
         font-size: 20px;
      }

      & .first-page__first-image {
         width: 100px;
         height: 100px;
      }
   }

  

   .first-page__first-text {
      position: absolute;

      top: 156px;

      width: 352px;
      height: 128px;
    
      & p {
         left: 35%;
       
      }
   }
   .first-page__left-image {
      right: 150px;
   }
   .first-page__second-part {
      width: 300px;
   }
.animated_text_letters p, .first-page__right-text p{
   z-index: 2222;
   font-size: 30px;

}
   .text-main {
      display: block;
      // position: absolute;

      left: 15px;
   }
   .text-mainpage {
      display: block;
      position: absolute;

      left: 15px;
   }
   .container-main-page {
      right: 0;
      left: 0;
      padding-bottom: 5px;
   }
 
   .first-page__second-part {
      position: relative;
      justify-content: center;

      margin: 0 0 0 15%;
      .first-page__left-image {
         margin-left: 15px;

         & svg {
            right: 100px;
         }
      }
   }

   // .first-page__first-image {
   //    margin-left: 300px;
   // }

   .first-page__main-container {
      .first-page__first-text {
         left: -20px;
      }

      & .first-page__first-image {
         position: relative;
         height: 140px;
         width: 400px;

         & .blob-container {
            position: absolute;
            right: 110px;
            // height: 300px;
            // margin-right: -120px;
            & .blob-one {
               height: 100px;
               width: 204px;
            }
            & .blob-two {
               position: relative;
               top: 35px;
               right: -5px;
               width: 100px;
               height: 100px;
            }
            & .blob-three {
               height: 100px;
               width: 204px;
            }
         }
      }

      & .first-page__yellow-image {
         width: 204px;

         // margin-left: 80px;
         // margin-top: -60px;
      }
      & .first-page__orange-image {
         // margin-top: 50px;
         // margin-left: 10px;
         // height: 155px;
      }
   }

   .second-part__top-part {
      flex-direction: column;
   }
   .first-page__second-part .first-page__right-text {
      max-width: 390px;
      // width: 297px;
      height: 128px;
      font-size: 32px;
      position: relative;

      margin-top: -50px;
      left: 75px;
      z-index: 5555;

      & p {
         // max-width: 20px;
         font-size: 30px;
         
      }
   }
   .first-page__left-image svg {
      // position: absolute;
      width: 97px;
      margin-right: -10px;
      // left: 90px;
      // right: 78.92%;
      // top: 388.36px;
   }

   .first-page__central-text {
      width: 280px;
      height: 128px;
      font-size: 14px;
   }

   .first-page__third-part {
      margin-top: 50px;
      & .first-page__central-text p {
         font-size: 14px;
      }
   }

   .second-part__top-image {
      width: 270px;
   }
   .second-part__man-image {
      width: 280.24px;
      height: 243.31px;
      left: 70px;
   }
   .second-part__top-image-dop1 {
      margin-left: -140px;
   }
   .second-part__top-image-dop2 {
      & svg {
         width: 116.19px;
      }
      margin-top: -250px;
   }
   .second-part__top-image-dop3 {
      & svg {
         width: 63.03px;
      }
      top: 180px;
      margin-right: -60px;
   }
   .first-page__central-button {
      display: block;
   }

   .second-part__top-image .second-part__top-image-dop1 {
      left: 249px;
      top: 42px;
      transform: scale(60%);
   }
   .second-part__top-text-top {
      width: 207px;
      height: 64px;
      font-size: 32px;
   }

   .first-page__central-button button {
      display: block;
      // margin: 120px auto 30px auto;
   }

   .second-part__man-image {
      left: 0;
   }


   .test-anim__main-container{
      margin-top: 130px;
   }

   .second-part__bottom-text-a-lot-of-text {
      // width: 365px;
      margin-top: -50px;
      margin-bottom: 40px;
      height: 252px;
      font-size: 14px;
   }


   .second-part__top-text {
      margin: 37px 0 0 155px;
      width: 207px;
   }
   .second-part__top-text-top {
      width: 207px;
      height: 64px;
      left: 151px;
      top: 37px;
      & p {
         font-size: 32px;
      }
   }

   .second-part__top-text-bottom {
      width: 212px;
      // height: 159px;
      left: 155px;
      & p {
         font-size: 14px;
      }
   }

   .second-part__bottom-text-rotated {
      margin-top: -90px;
      // margin-left: 50px;

      & svg {
         width: 452.49px;
         height: 437.53px;
         // margin-left: -50px;
      }
      & path {
         
         // transform: rotate(17.17deg);
      }
   }
   .second-part__wrapper-video {
      display: block;
      margin: auto;
      // width: 368px;
   }
   .second-part__wrapper-video {
      margin-top: 200px;
   }
   .third-part__top-text {
      width: 362px;

      & p {
         font-size: 32px;
      }
      top: 400px;
   }

   .third-part__flex-row__cell {
      width:50%;
      height: 300px;
      border: none;

      & .cell_container {
         position: relative;
         left:0;
         display: flex;
         flex-direction: column;
         vertical-align: center;
         margin: 40px 0 0 10px;
      }
      & .cell__number-cell {
         // width: 277.39px;
         height: 49px;
         font-size: 48.5437px;
         margin: 0;
      }
      & .cell__name-cell {
         font-size: 24px;
      }
      & .cell__list {
         padding: 0;
         margin-top: 33px;
         // width: 215.37px;
         font-size: 14px;
         & ul {
            padding: 0 0 0 10px;
         }
      }
   }

   .second-cell__img-one,
   .second-cell__img-two {
      & svg {
         width: 156.94px;
         height: 153.45px;
         transform: rotate(-20.39deg);
      }
   }

   .second-cell__img-one {
      right: 20px;
      top: 124px;
      & svg {
         // margin-left: 100px;
         width: 120px;
      }
   }
   .second-cell__img-two {
      left: 80px;
      top: 120px;
      & svg {
         margin: 0;
         width: 120px;
         // margin-left: 150px;
         // margin-top: 50px;
      }
   }

   .second-cell__img-dop-one {
      & svg {
         width: 63px;
      }

      left: 80px;
      top: 10px;
   }

   .second-cell__img-dop-two {
      & svg {
         width: 37px;
      }
      bottom: 20px;
      left: 70px;
   }

   .third-cell {
      // margin: 31px;
   }
   .third-part__image-main-img {
      bottom:200px;
      right: 20px;
      & svg {
         width: 150px;
      }
   }

   .third-cell_main-image{
      display: none;
   }
   .thirdCellMainImgp1, .thirdCellMainImgp2{
      display: block;
      width: 150px;
   }
   .thirdCellMainImgp2{
      position: relative;
      top: 60px;
   }
   .thirdCellMainImgp1{
      position: relative;
      left: 10px;
      top: 20px;
   }

   .third-part__flex-row__cell .third-part__image-dop1 {
      top: 40px;
      left: 50px;
      & svg {
         width: 15.67px;
      }
      order: 0;
   }

   .third-part__flex-row__cell .third-part__image-dop2 {
      & svg {
         width: 41px;
      }
      top: 210px;
      left: 120px;
   }

   .third-part__flex-row__cell .third-part__image-dop3 {
      & svg {
         width: 63px;
      }
      top: 100px;
      left: 20px;
   }

   .third-part__six-cell-main-img {
      & svg {
         width: 200.68px;
      }
   }
 

   .third-part__six-cell .third-part__six-cell-dop1 {
      top: 66px;
      left: 65px;
      & svg {
         width: 25px;
      }
   }
   .third-part__six-cell .third-part__six-cell-dop2 {
      left: 20px;
      top: 100px;
      & svg {
         width: 25px;
      }
   }

   .third-part__six-cell .third-part__six-cell-dop3 {
      top: 140px;
      left: 265px;
      & svg {
         width: 19px;
      }
   }

   .third-part__six-cell .third-part__six-cell-dop4 {
      top: 160px;
      left: 100px;
      & svg {
         width: 40px;
      }
   }
 

   .third-part__six-cell .third-part__six-cell-dop5 {
      top: 90px;
      left: 10px;
      & svg {
         width: 20px;
      }
   }
   .signup-page__signup-inputs-container {
      width: 70%;
      display: block;
      margin: auto;

      & .dop-info-icon-one{
         max-width: 200px;
         margin: -55px 0 0 115px;
      }

      & .dop-info-icon-two{
         max-width: 300px;
         margin: -55px 0 0 25px;
      }

      & .dop-info-icon-three{
         max-width: 300px;
         margin: -85px 0 0 25px;
      }
   }
   .signup-page__signup-inputs-container
      .inputs-container__label
      h2
      .info-icon {
      right: 230px;
   }
   .inputs-container__date-time-container {
      & input {
         // max-width: 160px;
      }
      & .date-time-container__date-logo {
         left: 80%;
      }
   }

   .signup-page__signup-container {
      display: block;
      flex-direction: none;
   }
   .signup-page__signup-cell-border {
      display: none;
   }
   .signup-page__signup-inputs-container {
      width: 90%;
   }

   .arrow-sign-up{
      display: none;
   }
   .inputs-container__signup-button {
      // max-width: 350px;
   }
   .inputs-container__signup-button
      .inputs-container__signup-button-text
      .arrow-sign-up {
      margin-right: -27px;
   }

   .list-container__text-active {
      max-width: 300px;
      margin: auto auto 40px auto;
     
   }
   .about__about-title {
      & h2 {
         margin-top: 150px;
         font-size: 32px;
      }
   }
   .about__list-container ul {
      display: block;
      margin: auto;
      padding: 0;
      width: 90%;
   }

   .about__list-container .list-container__title-button {
      & p {
         font-size: 15.367px;
         padding-left: 10px;
         padding-top: 16px;
         padding-bottom: 16px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: clip;
      }
      & .title-button__title {
         margin-top: 0;
         margin-bottom: 0;
      }

      & .title-button__button {
         margin-right: 20px;
      }
      & .title-button_open-button_dop {
         // right: 26px;
         // top: 20px;
      }
   }

   .about__list-container .list-container__text-active p {
      font-size: 11.6835px;
      margin: 0;
   }

   .road-map__title {
      font-size: 32px;
      margin-top: 130px;
   }

   .kula-kul__title {
      display: block;
      margin: auto;
      font-size: 32px;
      width: 307px;
   }

   .kula-kul__flex-container {
      margin: auto;
      // width: 360px;
      margin-top: 58px;
   }
   .kula-kul__flex-container-cell .kula-kul__flex-container-cell-number {
      font-size: 48.7365px;
   }
   .kula-kul__flex-container-cell .kula-kul__flex-container-cell-text {
      font-size: 14px;
   }

   .kula-kul__bottom-title {
      margin: 62px auto;
      width: 307px;
      font-size: 32px;
   }
   .kula-kul__bottom-final-part-container {
      margin-top: 55px;
      margin-bottom: -50px;
   }

   .privacy-policy{
      display: none;
   }
}




///////////////////////
@media (max-width: 500px) {
   .first-page__main-container{
      margin-left: 20px;
   }
   .first-page__first-text {
      & p {
         left: 10%;
      }
   }

   .first-page__left-image svg {
      left: 15px;
   }
   .first-page__second-part {
      margin: 0;
   }
}

@media (max-width: 385px) {
   .right-label-counter {
      margin-right: 24px;
   }
   .signup-page__signup-inputs-container
      .inputs-container__label
      h2
      .info-icon {
      right: 220px;
   }
   .signup-page__signup-inputs-container {
      & input {
         width: 100%;
      }
   }
   .signup-page__signup-inputs-container .date-time-container__date-logo,
   .signup-page__signup-inputs-container .date-time-container__date-logo {
      //   left: 120px;
   }
   .inputs-container__signup-button {
      width: 100%;
   }
   .about__list-container .list-container__title-button .title-button__title {
      white-space: nowrap;
   }

   .about__list-container .list-container__title-button .title-button__button {
      // margin-right: 10px;
   }
   .inputs-container__signup-button
      .inputs-container__signup-button-text
      .arrow-sign-up {
      right: 90px;
   }

   .about__list-container li:hover {
      padding: 0;
   }
}
