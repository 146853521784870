
/* ==========================================================================
   Section design
   ========================================================================== */
   html, body {
	margin: 0;/*cursor: none;*/
    background: black;
}
a {
	cursor: none;
}
button {
	cursor: none;
}
.content {
	width: 90%;
	max-width: 1200px;
	margin: auto;
	height: 100%;
}
.cursor {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 50%;
	left: 50%;
	margin: -12px 0 0 -12px;
	// background: linear-gradient(#fe8911, #ff0047);
	border-radius: 100px;
	backface-visibility: hidden;
	transition: transform 0.3s ease-out;
}

@media (pointer: coarse) {
.cursor {
	display: none;
}
}
.cursor.is-moving {
	transform: scale(0.6);
}
.parallax {
	position: relative;
	max-width: 100%;
	height: 500px;
	// overflow: hidden;

	left: 0;
	z-index: 8890;
}
.parallax-img {
	position: absolute;
	width: 100%;
	height: 750px;
	left: 0;
	background-repeat: no-repeat;
	background-position: top center;
}
.p-img-1 {
	// background-image: url("./img/p-img1.png");
	background-size: cover;
	opacity: 1;
	animation-name: image-glove;
	animation-duration: 2000ms;
	animation-delay: 0s;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

}
.p-img-2 {
	// background-image: url("./img/p-img2.png");
	background-size: cover;
	opacity: 1;
	animation-name: image-glove;
	animation-duration: 2000ms;
	animation-delay: .4s;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
    // filter: blur(1px);
}
.p-img-3 {
	// background-image: url("./img/p-img3.png");
	background-size: cover;
    // filter: blur(2px);
	
}
.p-img-4 {
	// background-image: url("./img/p-img4.png");
	background-size: cover;
	opacity: 1;
	animation-name: image-glove;
	animation-duration: 2000ms;
	animation-delay: 1.5s;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
    // filter: blur(3px);
	width: 0;
}
// @keyframes image-glove {
// 	0%{
// 		opacity: 0.3;
// 	}
// 0%{
// 		opacity: 1;
// 	}
// }


.man-in-hat__man-image {
    position: relative;
    z-index: 3333;
    display: block;
    // margin: auto;
    // top: 100px;
	left: -10%;
 }
 
 .man-in-hat__top-image {
    position: relative;
    display: block;

	& .man-in-hat__top-image-dop0 {
		// position: absolute;
		// top: 290px;
		//  margin-left: 60px;
		// z-index: 2222;
  
	 }
 
 
    & .man-in-hat__top-image-dop1 {
       position: absolute;
	   margin-top: -500px;
       margin-left: -5%;
       z-index: 2222;
 
    }
    & .man-in-hat__top-image-dop2 {
       position: absolute;
       margin-top: -520px;
        margin-left: -9%;
       z-index: 4444;
    }
    & .man-in-hat__top-image-dop3 {
		margin-top: -500px;
		margin-left: -10%;
		z-index: 2222;
    }
 }






 