.mvp-second-part-number {
   font-size: 35px;
   color: #1E56E1;
}

.mvp-second-part-label {
   font-size: 30px;
}

.mvp-second-part__main-text {
   font-size: 35px;
}
.mvp-second-part-descr {
   font-size: 15px;
   line-height: 17px;
}
.mvp-second-part_li {
   list-style-type: circle;
   font-size: 15px;
   line-height: 17px;
}

@media (max-width: 768px) {
   .mvp-second-part__flex-text-container {
      gap: 1px !important;
      width: 230px;
   }
   .mvp-second-part__main-text {
      font-size: 35px !important;
      padding-top: 50px !important;
      font-weight: 700;
   }

   .mvp-second-part-number {
      font-size: 35px !important;
   }
   .mvp-second-part-label {
      font-size: 30px !important;
      line-height: 30px !important;
      
   }
   .mvp-second-part-descr {
      font-size: 15px !important;
      line-height: 15px !important;
      font-weight: 200;
      font-family: 'HelveticaNeueCyr';
   }
   .mvp-second-part_li {
      font-weight: 200;
      font-size: 15px !important;
      line-height: 15px !important;
      font-family: 'HelveticaNeueCyr';
   }
}
