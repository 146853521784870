
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Neue+Machina:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=KyivType+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai:wght@400&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

* {
   box-sizing: border-box;
   margin: 0;
}

body::-webkit-scrollbar {
   width: 0.5em;
 }
  
 body::-webkit-scrollbar-track {
   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
 }
  
 body::-webkit-scrollbar-thumb {
   background-color: rgba(30, 86, 225, 1);
   outline: 1px solid rgba(30, 86, 225, 1);
 }

.container {
   /* max-width: 58%; */
   margin: auto;
}

.container-main-page {
   padding: 0 15px 0 15px; 
   max-width: 1230px; 
   margin: 0 auto;
    /* width: 80%;  */

}

/* @font-face{
  font-family: 'Neue Machina';
  src: local("Neue Machina"),
 url("./fonts/NeueMachina/NeueMachina-Regular.ttf") format("truetype");
}



@font-face{
  font-family: 'HelveticaNeueCyr';
  src: local("HelveticaNeueCyr"),
 url("./fonts/HelveticaNeueCyr/HelveticaNeueCyr-Light.ttf") format("truetype");
}
 */

@media (max-width: 750px) {



   .container-main-page {
      overflow: hidden;

   }
}

