
.container-wearewhat{
    position: relative;
    top: -50px;
  }
  
  
  .test-anim{
    position: relative;
  
  }
  .test-anim__main-container {
    position: relative;
    // left: -240px;
    // top: 250px;
    // height: 3000px !important;
    //  margin-left: -205px;
    display: flex;
    flex-direction: row;
    // margin-right: auto;
    justify-content: center;
    
    
  }