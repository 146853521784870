.kula-kul__three-things {
   margin-top: 156px;
}

.kula-kul__title {
   margin-top: 18px;
   font-family: 'Neue Machina';
   font-style: normal;
   font-weight: 400;
   font-size: 64px;
   line-height: 100%;
}

.kula-kul__flex-container {
   display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  

   margin-top: 59px;

}

.kula-kul__flex-container-cell {
    
   border: 1px solid #373737;
   text-align: left;
   height: 340px;
   width: 554px;

   & .kula-kul__flex-container-cell-number {
      font-style: normal;
      font-weight: 300;
      font-size: 75px;
      line-height: 100%;
      margin: 95px 60px 36px 60px;
   }
   & .kula-kul__flex-container-cell-text {
      margin: 0 60px 23px 60px;
      max-width: 411px;

      font-family: 'HelveticaNeueCyr';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      
   }
}

.kula-kul__bottom-title {
   margin-top: 29px;
   width: 100%;
   font-style: normal;
   font-weight: 400;
   font-size: 41px;
   line-height: 100%;
   text-align: center;
}

.kula-kul__bottom-final-part-container{
    display: block;
    justify-content: center;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}