.info-icon-container {
   position: relative;
   display: flex;
   flex-direction: row;
   border: 1px solid #1c52d6;
   background: #000;
   // background-image: url('./Bottom Center Tip.svg') no-repeat;
   top: 0;
   left: 0;
  
   right: 38.5px;
   bottom: 5px;
   z-index: 8888;
   

   & h4 {
      padding: 8px 12px 8px 12px;
      // display: -webkit-box;   
      // -webkit-line-clamp: 3;   
      // -webkit-box-orient: vertical;     
      overflow: hidden; 
      height: 100%;
      width: 100%;
     
         margin: auto;
         text-size-adjust: auto;
   
         font-family: 'Roboto';
         font-style: normal;
         font-weight: 400;
         font-size: 14px;
         line-height: 16px;
         letter-spacing: 0.01em;
         color: #FFFFFF;
   
   }
}


.info-icon-arrow-main{
   position: absolute;
   width: 100%;
   display: block;
   margin: auto;
   justify-content:center;
   bottom: -13.5px;
align-items:center;
   & svg{

      margin: auto;
   }
}

