.blob-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
   height: 200px;

  }

  .blob-one{
    position: absolute;
    width: 700px;
    height: 700px;
    top: 10px;
    
    // background: #fff
  }
  .blob-one:nth-child(2){
    // filter: blur(10px)
  }