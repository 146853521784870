.header-wrapper {
   height: 90px;
   position: fixed;
   display: block;
   top: 0;
   left: 0;
   right: 0;
   // width: 100%;
   z-index: 9999;
   // background-color: black;
   background-color: rgba(0, 0, 0, 0.65);
   -webkit-backdrop-filter: blur(58px);
   backdrop-filter: blur(58px);

}

.header {
   max-width: 1150px;

   height: 90px;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;

   overflow: hidden;

   margin: auto;
   align-items: center;
   justify-content: center;
   //    border: 1px solid white;
   z-index: 9999;

   & img {
      width: 90px;
      height: 44px;
      display: block;
      position: relative;
      top: 50%;

      //   margin-left: 318px;
   }
}

.header-all {
   //    margin-top: 37px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   // white-space: nowrap;
}

.header-left-side {
   display: flex;
   flex-direction: row;
   padding-bottom: 30px;
   & img:hover {
      cursor: pointer;
   }
}

.header-right-side {
   display: flex;
   flex-direction: row;
   margin-top: 25px;
   //  width: 100%;
   justify-content: right;

   width: 40%;
   float: left;

   & button:hover {
      // color: #ffb40a;
      // background: #ffb40a;
      border: 1.5px solid #1e56e1;
      cursor: pointer;
      color: tint(#ffb40a, 75%);

      &:before {
         transform: translateX(0);
      }
   }
}

.center-header {
   margin-left: 44px;
   width: 105px;
   height: 16px;

   // width: (calc(400 * ((100vw - 820px) / 680)));
   & .center-buttonz {
      display: flex;
      flex-direction: row;
      gap: 10px;
   }
   & .center-buttonz button {

      white-space: nowrap;
      background: inherit;
      border: none;
      font-family: 'HelveticaNeueCyr';
      font-weight: 550;
      font-size: 13px;
      line-height: 120%;
      letter-spacing: 0.5px;
      align-items: center;
      color: #787878;
      justify-content: center;
      margin-top: 30px;
      //   padding: 16px 20px;
      // gap: 10px;
      border: none;
      text-transform: uppercase;
      padding: 10px 3.5px 10px 3.5px;
      &:hover, &:hover a {
         cursor: pointer;
         color: #ffb40a;
         border: none;
         // padding: 8.5px 2px 8.5px 2px;

         // font-weight: 700;
      }
   }
   & img {
      margin-top: auto;
   }
}
.center-header .center-buttonz button:active {
   color: #ffffff;
}

.sign-up-button {
   width: 155px;
   height: 40px;
   color: #1e56e1;
   border: 1.5px solid #1e56e1;
   // margin-left: 200px;
   margin-right: 37px;
   margin-left: 37px;
   background-color: inherit;
   font-size: 14px;
}

.display-disable {
   display: none;
}

.right-label-counter {
   color: #ffb40a;
   text-align: right;
   white-space: nowrap;
   flex-wrap: nowrap;
   //    margin-right: 318px;

   font-variant-numeric: normal !important;

   & .time-left span {
    

      flex-wrap: nowrap;
         display: flex;
         flex-direction: row;
       
      & p {
         letter-spacing: 0.015em;
          font-family: 'HelveticaNeueCyr' !important;
   
         font-weight: 700;
         font-family: monospace;
         font-size: 20px;
         line-height: 20px;
         margin-left: 7px;
      }
   }
}

// @media (max-width: 1300px) {
//    .center-header {
//       & button {
//          white-space: normal;
//       }
//    }

// }

@media (max-width: 1160px) {
   .first-page__main-container p {
      font-size: 44px;
   }
}

@media (max-width: 1050px) {
   .sign-up-button {
      display: none;
   }
}

@media (max-width: 1027px) {
   .center-header {
      display: none;
   }
   .header-all {
      //  justify-content: space-evenly;
   }

   .header-left-side,
   .header-right-side {
      padding: 0 50px 0 50px;
   }
}
@media (max-width: 750px) {
   .header-left-side,
   .header-right-side {
      padding: 0;
   }
}
