.mvp-roadmap {
   & p {
      display: none !important;
   }
   & .road-map__title{
      margin: 0px auto 70px auto;
   }
}
.road-map__first-point {
   & .road-map-third-point__first-point {
      // & circle{
      //    filter: invert(18%) sepia(19%) saturate(2476%) hue-rotate(16deg)
      //    brightness(118%) contrast(119%) !important;
      // }
   }
}


@media (max-width: 768px) {
   #scroll-id2 {
      margin-top: -45px !important;

      & .button_container{
         margin-top: -50px !important;
      }
   }
}
