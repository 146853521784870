.mvp-header-right-side button {
   background: #3e54fc;
   color: white;
   & span {
      text-transform: uppercase;
      font-family: 'Neue Machina';
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
   }
}

.mvp-header-right-side {
   display: flex;
   flex-direction: row;
   margin-top: 25px;
   //  width: 100%;
   justify-content: right;

   float: left;
}

.mvp-header-wrapper {
   & h2 {
      color: #fff;
      text-align: center;
      font-family: Neue Machina;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.72px;
      text-transform: uppercase;
   }
}

.mvp-header {
   width: 75%;
}

.road-map-third-point {
}

@media (max-width: 760px) {
   .mvp-header-right-side {
      transform: scale(0.8);
      margin-right: 5% !important;
   }

   .mvp-header {
      width: 100%;

      & .button_container {
         padding-top: 20px !important;
      }
      & button {
         margin-left: 5% !important;
      }
   }
}
