.about__about-title {
   & h2 {
      font-family: 'Neue Machina';
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      text-align: center;
      margin-top: 219px;
   }
}

.about__list-container {
   display: block;
   margin: 46px auto 0 auto;
//    width: 70%;
   min-height: 84px;
   font-size: 36px;
   font-style: normal;
   font-weight: 400;

   & .list-container__title-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & p {
         margin: 24 0;
         font-style: normal;
         font-weight: 400;
         font-size: 36px;
      }

      & .title-button__title{
        margin: 24px 24px 24px 24px;
      }
      
   }
   & .list-container__text {
    display: none;
    transition: all 1s ease-in-out;
     opacity: 0;

    
     
   }

   & .list-container__text-active{
    display: flex;
    // margin: 0 40px;



    & p {
        font-family: 'HelveticaNeueCyr';
        text-align: left;
       font-weight: 400;
       font-size: 18px;
       line-height: 150%;
       margin: 0 24px 24px 24px;
       
    }
   }


   & li {
      list-style-type: none;
      border-bottom: 1px solid #373737;

      transition: all .3s ease-out;
      &:hover{
         background: #0e0e0e;
         cursor: pointer;
         padding: 0 20px 0 20px;
         transition: all .3s ease-out;
      }
      
      & .list-container__title-button{
         position: relative;
         transition: all .3s ease-out;

        

      }

      & .list-container__text{
         height: 0px;
      
         transition: all .3s ease-out;
         animation-name:  my-animation-close;
         animation-duration: 0.5s;
         animation-direction: alternate;
         animation-iteration-count: inherit;
      }
    
     & .list-container__text-active {
       height: 100px;
       background: #0e0e0e;
         cursor: pointer;
         padding: 0 20px 0 20px;
         transition: all .3s ease-out;
       transition: all .3s ease-out;
      animation-name:  my-animation-open;
      animation-duration: 0.5s;
      animation-direction: alternate;
      animation-iteration-count: inherit;
      


      & p {
         transition: all .3s ease-out;
       animation-name:  my-animation-opacity;
      animation-duration: 1.5s;
      animation-direction: alternate;
      animation-iteration-count: inherit;

      }
    }
   }
   & .li-open{
      cursor: pointer;
      padding: 0 20px 0 20px;
      transition: all .3s ease-out;
      background: #0e0e0e;
   }
   & button {
      margin-right: 42px;
      background-color: inherit;
      color: #1e56e1;
      border: none;
      cursor: pointer;
      // width: 26px;
   }
}
@keyframes my-animation-open {
   from {

     height: 0;
   }
 
   to {

     height: 100px;
   }
 }

 @keyframes my-animation-close {
   from {

     height: 100px;
   }
 
   to {

     height: 0;
   }
 }
 @keyframes my-animation-opacity {
   from {
      opacity: 0;

   }
 
   to {
      opacity: 1;
   }
 }


.title-button__button{

    margin: auto;
}


.title-button_container{
   position: relative;
}

.title-button_close-button{
   transform: rotate(90deg);
   transition: ease all 0.5s;

   &::after{
      content: '';
      border: 1px solid #1e56e1;
      border-radius: 3px;
      width: 26px;
      transition: ease 0.5s;
      
   }
   
   //  width: 26px;

}
.title-button_open-button_dop{
   position: absolute;
   transform: rotate(90deg);
      transition: ease all 0.5s;
   top: 0px;
   right: 0px;
   &::after{
      content: '';
      border: 1px solid #1e56e1;
      // border-radius: 3px;
      width: 26px;
      background: #1e56e1;
      transition: ease 0.5s;
      
   }
}


.title-button_open-button {
   transform: rotate(0deg);
   transition: ease all 0.5s;
   &::after{
      content: '';
      border: 1px solid #1e56e1;
      // border-radius: 3px;
      width: 26px;
      background: #1e56e1;
      transition: ease 0.5s;
      
   }
   
   //  & svg {
   //       top: 5px;
   //      margin-left: 5px;
   //  }

}

