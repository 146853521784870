


.road-map-img {
    /* margin-top: 115px; */
    position: relative;
    display: block;
   
    margin: auto;
    // width: 50%;
 
 }
 .road-map__main-img {

    // & .road-map__first-point svg{
    //     display: none;
    // }
    // & .road-map__second-point svg{
    //     display: none;
    // }
    // & .road-map__third-point svg{
    //     display: none;
    // }
    // & .road-map__four-point svg{
    //     display: none;
    // }
    // & .road-map__five-point svg{
    //     display: none;
    // }
   
}

//  @media (max-width: 700px) {
//     // .road-map-img {
//     //     transform: rotate(70deg);
//     //  }
//      .container-road-map {
//         width: 100%;
//      }
//      .container-road-map  {
//         margin: 0;
//         width: 338px;
//         & svg {
//         //   display: none;
//         width: 70%;
//         }


//      }
   


//  }