.signup-page__title {
   width: 100%;
   display: block;
   margin: 108px auto 0 auto;
   & p {
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 100%;
   }
}

.signup-page__signup-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: center;
   margin: 40px auto 0 auto;

   & .signup-page__signup-cell-border {
      max-width: 557px;
      max-height: 900px;
      width: 100%;
      height: auto;
      border: 1px solid #373737;
   }
   & .signup-page__signup-cell {
      max-width: 557px;
      max-height: 900px;
      width: 100%;
      height: auto;
      // border: 1px solid #373737;
   }
}
.signup-page__signup-image {
   position: relative;
}

.signup-page__signup-cell {
   position: relative;
}
.signup-image__main-image {
   position: relative;
   z-index: 7777;
}
.signup-image__dop1 {
   position: absolute;
   z-index: 3333;
   top: 346px;
   left: 130px;
}
.signup-image__dop2 {
   position: absolute;
   z-index: 3333;
   top: 490px;
   left: 40px;
}
.signup-image__dop3 {
   position: absolute;
   z-index: 3333;
   top: 110px;
   left: 278px;
}

.signup-page__signup-inputs-container {
   width: 70%;
   display: block;
   margin: auto;

   & input {
      width: 100%;
      height: 40px;
      background-color: inherit;

      text-indent: 10px;
      color: #cccccc;

      outline: none;
     
   }
   & #default-input {
      border: 1px solid rgba(255, 255, 255, 0.25);
       &:hover{

         border: 1px solid  #1e56e1;
      }
   }

   & select {
      -webkit-appearance: none;
      appearance: none;
      color-scheme: dark;
      background-image: url('arrow-select.svg');
      background-size: 15px;
      background-repeat: no-repeat;
      background-position: calc(100% - 15px) center;
   }

   & #list-casual {
      width: 100%;
      height: 40px;
      text-indent: 10px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      background-color: inherit;
      color: #cccccc;
      outline: none;
      cursor: pointer;

      &:hover {
         border: 1px solid  #1e56e1;
      }
   }

   & .date-time-container {
      position: relative;
   }

   & .date-time-container__date-logo {
      position: absolute;
      left: 140px;
      
    top: 63%;
     
    
   }

   & .input-date {
      border: 1px solid rgba(255, 255, 255, 0.25);
      position: relative;
      color-scheme: dark;
      &:hover {
         border: 1px solid  #1e56e1;
      }
   }
   & #input-date::-webkit-calendar-picker-indicator {
      filter: invert(0.5);
      color: blue;
      // margin-right: 10px;
      background: transparent;
      bottom: 0;

      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
   }



   & #input-time{
      position: relative;
      border: 1px solid rgba(255, 255, 255, 0.25);
      color-scheme: dark;
      &:hover {
         border: 1px solid  #1e56e1;
      }
   }

  
   & #input-time::-webkit-calendar-picker-indicator {
      filter: invert(0.5);
      color: blue;
      // margin-right: 10px;
      background: transparent;
      bottom: 0;

      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
   }

   & h2 {
      font-family: 'Neue Machina';
      font-style: normal;
      font-weight: 400;
   }
   & p {
      text-align: left;
      width: 100%;
      height: 22px;
      margin-top: 18px;
   }
   & .inputs-container__label-first{
      display: flex;
      flex-direction: column;

      & .inputs-container__label-first-text{
         text-align: left;
      }
    
   }
   & .inputs-container__label {
      display: flex;
      // vertical-align: middle ;
      justify-content: left;
      text-align: left;



      & h2 {
         position: relative;
         font-size: 24px;
         text-align: left;

       
      }
   }
}


.first-info-icon, .first-info-icon svg{
   display: flex;
   flex-direction: row;

}

.react-datepicker__header {
   text-align: center;
   background: rgba(0, 0, 0, 0.65);
   border-bottom: 0px;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
   padding-top: 8px;

   position: relative;
  
   & .react-datepicker__day-names .react-datepicker__day-name, .react-datepicker__current-month{
      color:#1e56e1;
      
   }
 
 }
 .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
   border-color: #1e56e1;
   
 }
 
 .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
   color:#1e56e1;
   border: 1px solid rgba(0, 0, 0, 0.65);
   &:hover{
      background: none;

      border: 1px solid #ffb40a;
   }
 }
 .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
   border-top-color: #ffb40a;
 }
 .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
   border-bottom-color: #ffb40a;
 }

.react-datepicker {
   font-size: 0.8rem;
    background-color: rgb(0, 0, 0);

   border: 0px;
   border-radius: 0;
   display: inline-block;
   position: relative;
 }
.phone-input {
   border: none;
   background-color: inherit;
}
.PhoneInput {
   border-left: 1px solid rgba(255, 255, 255, 0.25);
   border-top: 1px solid rgba(255, 255, 255, 0.25);
   border-bottom: 1px solid rgba(255, 255, 255, 0.25);
   // border: 1px solid rgba(255, 255, 255, 0.25);
   background-color: inherit;
   padding-left: 10px;
   & input {
      border: none;
      border-right: 1px solid rgba(255, 255, 255, 0.25);
   }
}

.inputs-container__second-label {
   margin-top: 44px;
}

.info-icon {
   // width: 16px;
   // height: 16px;
   // color: white;

   // margin-top: 3px;
   margin:  auto 0 auto 11px;
   cursor: pointer;
}

.inputs-container__date-time-container {
   display: flex;
   flex-direction: row;
   & .date-time-container {
      width: 100%;
   }
   & .date-time-container-date {
      margin-right: 29px;
   }
}
.signup-button-disabled {
   &:hover {
      // background-color: #1e56e1;

      cursor: auto;
   }
}

.signup-button-enable {
   // &:hover {
   //    background-color: #ffb40a;
   //    color: black;
   //    cursor: pointer;
   // }
}
.inputs-container__signup-button {
   position: absolute;
   // max-width: 400px;
   top: 0;
   left: 0;
   height: 100%;
   border: none;
   width: 100%;
   // margin-top: 45px;
   // color: white;
   // background: #1e56e1;

   & .inputs-container__signup-button-text {
      position: relative;
      display: block;
      // margin: auto 0 20px 0;
      //   margin-left: 30px;
      // font-weight: 550;
      height: 100%;
      font-size: 18px;
      letter-spacing: 0.095em;
      text-transform: uppercase;
      font-family: 'HelveticaNeueCyr';
      font-style: normal;
       color: #ffffff;
      // transition: all 1.5s ease-in-out;

      & p {
         
         text-align: center;
         right: -50%;
         // transition: all 0.5s ease-in-out;
      }

      & .signup-text {
         // text-decoration: none;
         // display: table-cell;
         // vertical-align: middle;
         margin-top: -25px;
         opacity: 0;
         // transition: all 0.5s ease-in-out;
      }
      & .signup-text-active {
         
         margin-top: -25px;

         opacity: 1;
         // transition: all 0.5s ease-in-out;
      }

      & .complete-text {
         
         opacity: 0;
         // transition: all 0.5s ease-in-out;
      }
      & .complete-text-active {
         
         opacity: 1;
         // transition: all 0.5s ease-in-out;
      }
      & .arrow-sign-up {
         position: absolute;
         transform: scale(80%);
         top: -4px;
         right: 95px;
      }
      & .inputs-container__spinner-loading {
         position: absolute;
         display: block;
         left: 43%;
         top: -25px;

         //     margin-top: -40px;
      }

      .signup-text-complete {
         color:  #ffffff;

         // transition: all 0.5s ease-in-out;
      }

      .signup-text-error {
         position: absolute;
         color: red;
         font-size: 12px;
         top: -27px;
         left: 12px;
         line-height: 16px;
         // transition: all 0.5s ease-in-out;
      }
   }
}


.dop-info-icon-one{
   margin: -35px 0 0 45px;
}
.dop-info-icon-two{
   margin: -55px 0 0 0px;
}
.dop-info-icon-three{
   margin: -75px 0 0 45px;
}

.dop-info-icon{
   max-width: 390px;
  
    position: absolute;
      width: 100%;
      

   // width: 130px;
   // height: 110px;
   // left: 250px;

    background: #000;
   //  text-size-adjust: auto;
   // color: #FFFFFF;
   // border: 1px solid 
   // rgba(28, 82, 214, 1);
    z-index: 8888;
   // transition: all 1s ease-in-out;

//    & p {
//       font-family: 'Roboto';
// font-style: normal;
// font-weight: 400;
// font-size: 14px;
// line-height: 16px;
// letter-spacing: 0.01em;
// padding: 8px 12px 8px 12px;
//    }

}

.dop-info-icon-open{
   display: block;
}
.dop-info-icon-close{
   display: none;
}


.input-title{
   margin-bottom: 2px;
}
