.road-map__title {
   display: block;
   margin: 219px auto 156px auto;
   text-align: center;
   font-style: normal;
   font-weight: 400;
   font-size: 64px;
   line-height: 100%;
}

.container-road-map {
   width: 1100px;
   max-height: 842px;
   position: relative;
   position: relative;
   display: block;
   margin: auto;
   justify-content: center;
//  overflow: hidden;

   & .road-map__main-img {
      position: relative;
padding-bottom: 50px;
      & .main-img-road-map-svg {
         stroke: #fff;
         //    position: absolute;
         //    width: var( var(100%));
         width: 900px;
         // stroke: var(var(--red));
         opacity: 0.8;
         // transform: translateX(0) rotate(180deg);
         z-index: 2222;
      }

      & .road-map__first-point {
         position: absolute;
         display: flex;
         flex-direction: column;
         top: -48px;
         left: calc(50% - 115px);

         z-index: 5555;
         & svg {
            margin-top: 5px;
         }
         .main-img-road-map-svg {
            // background-color: black;
            stroke-dashoffset: 0;
            stroke-width: 5%;
            fill: none;
         }
      }

      & .road-map__second-point {
         position: absolute;
         display: grid;
         flex-direction: column;
         justify-content: center;

         z-index: 5555;
         top: 300px;
         left: calc(50% - 115px);

         margin-left: -230px;

         & svg {
            margin-bottom: 15px;
            // margin-left: -50px;
         }
         & .second-point-list {
            margin-left: -50px;
         }
      }

      & .road-map__third-point {
         position: absolute;
         display: flex;
         flex-direction: row;
         justify-content: center;

         left: calc(50% - 115px);
         top: 120px;

         margin-left: 250px;
         z-index: 5555;
         & svg {
            margin-right: 20px;
         }
      }

      & .road-map__four-point {
         position: absolute;
         display: grid;
         flex-direction: column;
         justify-content: center;
         z-index: 5555;

         top: 457px;
         left: calc(50% - 115px);

         margin-left: 10px;

         & svg {
            margin-bottom: 15px;
            // margin-left: -50px;
         }
         & .four-point-list {
            margin-left: -30px;
         }
      }

      & .road-map__five-point {
         position: absolute;
         display: grid;
         flex-direction: column;
         justify-content: center;
         z-index: 5555;
         top: 460px;
         left: calc(50% - 115px);

         margin-left: 370px;

         & svg {
            margin-bottom: 15px;
            // margin-left: -50px;
         }
         & .five-point-list {
            margin-left: -40px;
         }
      }

      //  background: linear-gradient(108.9deg, rgba(255, 255, 255, 0) 6.27%, rgba(255, 255, 255, 0.296) 30.06%, rgba(255, 255, 255, 0.3) 65.73%, rgba(255, 255, 255, 0) 86.42%);
      // color:white;

      //         border: 5px solid #ededed;
   }
}

.container-road-map {
   & .road-map__main-img {

      & .road-map__first-point {
         left: 450px;
      }
      & .road-map__second-point {
         margin-left: 0;
         left: 204px;
      }
      & .road-map__third-point {
         margin-left: 0;
         left: 690px;
      }
      & .road-map__four-point {
         margin-left: 0;
         left: 480px;
      }
      & .road-map__five-point {
         margin-left: 0;
         left: 803px;
       
      }
   }
}

@media (max-width: 1080px) {
   .container-road-map {
      transform: scale(0.7);
   }
}

@media (max-width: 890px) {
   .container-road-map {
      margin-left: -100px;
   }
}

@media (max-width: 750px) {
   .container-road-map {
      margin-left: -200px;
      margin-top: -250px;
      rotate: 55deg;
      transform: scale(50%);
      overflow: unset;
   }
   .container-road-map .road-map__main-img .road-map__second-point .second-point-list {
    margin-left: 150px;
    

}
   .road-map__first-point {
      rotate: (305deg);
        bottom: 100px;
        right: 200px;
   }
   .road-map__second-point {
    rotate: (305deg);

       & svg{
        margin-left: 194px;
       }
      right: 100px;
      z-index: 8888;
 }

 .container-road-map .road-map__main-img .road-map__third-point {
    margin-top: -91px;
    margin-left: -90px;
    rotate: (305deg);
        top: 100px;
 }
 .container-road-map .road-map__main-img .road-map__four-point {
    margin-top: 190px;
    margin-left: 118px;
    rotate: (305deg);
        top: 100px;
 }

 .container-road-map .road-map__main-img .road-map__five-point {
    margin-top: 160px;
    margin-left: 50px;
    rotate: (305deg);
        top: 100px;
 }
 .second-point-list{
    margin-left: 50px

}
}
