.second-part__top-part {
   display: flex;
   flex-direction: row;
   justify-content: center;
   margin: 50px auto 0 auto;

}
.second-part__man-image {
   position: relative;
   z-index: 8888;
}

// .second-part__top-image {
//    position: relative;
//    display: block;



//    & .second-part__top-image-dop1 {
//       position: absolute;
//       top: 90px;
//       left: 120px;
//       z-index: 2222;

//    }
//    & .second-part__top-image-dop2 {
//       position: absolute;
//       top: 290px;
//       left: 160px;
//       z-index: 2222;
//    }
//    & .second-part__top-image-dop3 {
//       position: absolute;
//       right: 250px;
//       bottom: 20px;
//    }
// }

.second-part__top-text {
      margin-top: 29px;
   // margin-right: -200px;
   // margin-left: 100px;
   text-align: left;
   display: flex;
   flex-direction: column;
   width: 308px;

}

.second-part__top-text-top {
   width: auto;
   height: 128px;
   & p {
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 100%;
   }
}

.second-part__top-text-bottom {
   // width: 324px;
   // height: 216px;
    margin-top: 29px;

   & p {
      font-family: 'HelveticaNeueCyr';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
   }
}

.second-part__bottom-text-rotated {

   margin-top: -50px;
}

.second-part__bottom-text-a-lot-of-text {
    width: 921px;
    height: 189px;
    display: block;
    margin: -120px auto 0 auto;
  & p {
    font-family: 'HelveticaNeueCyr';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */

 
    text-align: center;
  }
}

.second-part__main-video{
    position: relative;
    max-width: 1300px;
    max-height: 545px;
    width: 100%;
    display: block;
    margin-top: 66px;
    margin-left: auto;
    margin-right: auto;
    & .second-part__wrapper-video img {
        width: 100%;
        height: auto;
    }
}
.second-part__main-video-dop{
    position: absolute;
    top: 28%;
    left: 40%;
    width: 20%;
    height: auto;
    // top: -400px;
}


@media (max-width: 1330px) {
   .second-part__bottom-text-rotated {
      & svg { width: calc(700 * ((100vw - 320px) / 680));}}
   
}


@media (max-width: 1330px) {
   .second-part__bottom-text-a-lot-of-text{
      width: 100%;
   }
  
}