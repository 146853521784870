.root-mvp {
   margin: 0;
   line-height: normal;

   /* fonts */
   --font-neue-machina: 'Neue Machina';
   --font-helvetica-neue: 'Helvetica Neue';
   --font-kyivtype-sans: 'KyivType Sans';
   --font-inherit: inherit;
   --font-inter: Inter;

   /* font sizes */
   --font-size-11xl: 30px;
   --font-size-7xl: 26px;
   --font-size-2xs: 11px;
   --font-size-xl: 20px;
   --font-size-3xl: 22px;
   --font-size-41xl: 60px;
   --font-size-base: 16px;
   --font-size-38xl: 57px;

   /* Colors */
   --color-black: #000;
   --color-white: #fff;
   --color-goldenrod: #f4b641;
   --color-royalblue: #2c5ad9;
   --color-midnightblue: #290042;
   --color-red: #ff0000;
   --color-mediumslateblue: #3e54fc;

   /* Paddings */
   --padding-21xl: 40px;
   --padding-11xl: 30px;
   --padding-3xs: 10px;
   --padding-xl: 20px;

   /* border radiuses */
   --br-3xs: 10px;
}
