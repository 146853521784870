.mvp-first-page__first-text {
   text-align: left;
}

.mcp-first-page h2 {
   font-family: 'Neue Machina';
   font-style: normal;
   font-weight: 400;
   font-size: 4vw;
   line-height: 4vw;
}

.mvp-first-page__first-image {
   transform: scale(80%);
}

.mvp-first-part__buttons {
   width: 40%;
   & button {
      background: #3e54fc;
      width: 200px;
   }
   & span {
      text-transform: uppercase;
      font-family: 'Neue Machina';
      font-size: 15px;
      color: white;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
   }
}
.mvp-first-page__blob-one,
.mvp-first-page__blob-two {
   display: none;
}

@media (max-width: 1024px) {
   .mvp-first-part__buttons {
      width: 100%;
      & .button_container {
         transform: scale(0.7);
         // width: 400px;
         // & span {
         //    font-size: 20px;
         //    line-height: 20px;
         // }
      }
   }
   .mvp-first-part__buttons {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: auto;

      & .button_container {
         width: 400px;
         height: 60px;
      }
      & button {
         width: 400px;
         height: 60px;
      }
      & span {
         font-size: 25px;
         line-height: 20px;
      }
   }
}

@media (max-width: 760px) {
   .mvp-first-page__first-part {
      margin-top: 150px !important;
   }
   .mvp-first-page__first-image {
      display: none;
      // position: absolute !important;
      // left: 100px !important;
      // transform: scale(0.4);
      // margin: 0 !important;
      // // padding-right: 200px !important;
   }
   .mvp-first-page__blob-one,
   .mvp-first-page__blob-two {
      display: block;
      margin-right: 40px !important;
      margin-top: -100px;
      & .blob-container {
         & .blob-one {
            width: 150px !important;
            height: 150px !important;
            & svg {
               width: 200px !important;
            }
         }
         & .blob-two {
            width: 180px !important;
            height: 150px !important;
            margin-top: 70px;
            // margin-right: -60px;
            margin: 0;
            & svg {
               width: 220px !important;
               margin-top: 60px;
            }
         }

         .mvp-first-page_blob-container {
            .blob-container {
               & .blob-three {
                  height: 100px;
               }
            }
         }
         padding-top: 100px !important;
      }
   }

   .mvp-first-page__first-text {
      position: relative !important;
      left: 0 !important;
      width: 250px !important;
      padding-left: 10% !important;
      font-size: 30px !important;
      line-height: 32px !important;
      margin: 0 !important;
      margin-right: 0px !important;
   }
   .mvp-first-page_blob-container {
      margin-right: 40px !important;
      & .blob-container {
         & .blob-three {
            width: 150px !important;
         }
         padding-top: 100px !important;
      }
   }
   .mvp-first-page__second-text {
      width: 250px !important;
      font-size: 30px !important;
      line-height: 35px !important;
      text-align: right !important;
      margin-left: -50px;
   }
}

@media (max-width: 380px) {
   .mvp-first-page__blob-two {
      & .blob-container {
         & .blob-two {
            width: 200px !important;
         }
      }
   }
   .mvp-first-part__buttons {
      & .button_container {
         margin-left: -14px;
      }
   }
}
