.button_container {
   width: 155px;
   height: 40px;
   margin: auto;
}
.button_su {
   overflow: hidden;
   position: relative;
   display: inline-block;

   // border-radius: 3px;
}

.su_button_circle {
   background-color: red;
   border-radius: 1000px;
   position: absolute;
   left: 0;
   top: 0;
   width: 0px;
   height: 0px;

   margin-top: 0px;
   pointer-events: none;
   /*animation-timing-function: ease-in-out; */
}

.button_su_inner {
   display: inline-block;
   background-color: inherit;
   color: #1e56e1;
   border: 1.5px solid #1e56e1;
   font-size: 18px;
   font-weight: normal;
   width: 155px;
   height: 40px;

   text-align: center;
   // border-radius: 3px;
   transition: 400ms;
   text-decoration: none;
   //   padding: 22px;

   display: table-cell;
   vertical-align: middle;
   &:hover, &:hover span{
    cursor: pointer;
    transition: ease all 1.0s;
    color: black;

   }
}

.button_text_container {
   position: relative;

   font-family: 'HelveticaNeueCyr';
   font-style: normal;
   font-weight: 700;
   font-size: 18px;
   line-height: 20px;
   
   // letter-spacing: 0.06em;
}

.explode-circle {
   animation: explode 1s forwards;
}

.desplode-circle {
   animation: desplode 0.5s forwards;
}

@keyframes explode {
   0% {
      width: 0px;
      height: 0px;
      margin-left: 0px;
      margin-top: 0px;
      background-color: rgba(42, 53, 80, 0.2);
   }
   100% {
      width: 400px;
      height: 400px;
      margin-left: -200px;
      margin-top: -200px;
      background-color: rgba(255, 180, 10, 1);
   }
}

@keyframes desplode {
   0% {
      width: 400px;
      height: 400px;
      margin-left: -200px;
      margin-top: -200px;
      background-color: rgba(255, 180, 10, 1);
   }
   100% {
      width: 0px;
      height: 0px;
      margin-left: 0px;
      margin-top: 0px;
      background-color: rgba(255, 180, 10, 1);
   }
}
