
#home-section {
   margin: auto;
}

.first-page__main-container {
   position: relative;
   // left: -240px;
   margin-top: 200px;

   //  margin-left: -205px;
   display: flex;
   flex-direction: column;
   // margin-right: auto;
   justify-content: center;
   
   & p {
      max-width: 761px;
      max-height: 192px;
      bottom: 0;
      left: 0;
      font-family: 'Neue Machina';
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      // font-size: calc(28 * ((100vw - 320px) / 680));
      line-height: 100%;
      /* or 64px */
      text-align: left;

      color: #ffffff;
   }
}

.first-page__first-text {
   max-width: 761px;
   max-height: 192px;
   // width: 761px;
   // width: calc(400 * ((100vw - 320px) / 680));
   // margin-right: 52px;
   padding-left: 40px;

}

.first-page__first-image {
   position: absolute;
    top: -90px;

    right: 1rem;
    overflow: hidden;
    width: 500px;
    height: 400px;

   & .first-page__orange-image {
      position: relative;
      top: -95px;
      right: -50px;
   }
}

.first-page__second-part {
   display: flex;
   flex-direction: row;
   margin: 126px auto 0 400px;
   // margin-right: -12px;
   max-width: 800px;
   height: auto;
   justify-content: space-between;



   & .first-page__right-text {
      font-family: 'Neue Machina';
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 100%;
      /* or 64px */
      padding-right: 40px;
      text-align: right;

      color: #ffffff;
   }

   & .first-page__left-image{
      margin-left: -130px;
      margin-top: 30px;
   }
}

.first-page__third-part {
   margin-top: 136px;
   display: block;

   justify-content: center;
   text-align: center;
   & .first-page__central-text {
      max-width: 770px;
      max-height: 81px;
        margin: 0 auto;
      & p {
         font-family: 'HelveticaNeueCyr';
         font-style: normal;
         font-weight: 400;
         font-size: 18px;
         line-height: 150%;
         /* or 27px */

         text-align: center;

         color: #ffffff;
         & span {
            font-weight: bold;
            font-size: 20px;
         }
      }

   }
   .first-page__central-button {
    margin-top: 118px;
   //  & button:hover {
   //    background: #ffb40a;
   //    border: 1.5px solid #1e56e1;
   //    cursor: pointer;
    

   // }

   }
}

.sign-up-button-container {
   display: block;
   margin: auto;
   justify-content: center;
}

@media (max-width: 1140px) {
   .first-page__main-container, .first-page__right-text{
  
    & p, span {
      font-size: 42px;
      
    }

   }
   .first-page__second-part{
      justify-content: space-evenly;
   }



}

@media (max-width: 1030px) {
   // .first-page__first-image {
   //    width: 104px;
   // }
   .first-page__main-container, .first-page__right-text{
  
    & p, span {
      font-size: 32px;
      
    }

   }
   .first-page__second-part{
      justify-content: space-evenly;
   }


   
}





