.mvp-five-part__button {
   transform: scale(150%);
}

.mvp-five-part__kula-app {
   font-family: 'Neue Machina';
   font-style: normal;
   font-weight: 800;
   font-size: 6vw;
   line-height: 7vw;
   display: flex;
   // align-items: center;
   // text-align: center;
}

.mvp-five-part__kula-border {
   transform: scale(100%);
   margin: 10px 10px 10px 0;
   border: 1px solid white;
}
.mvp-five-part__text {
   font-size: 3.8vw;
   line-height: 3.8vw;
}
.mvp-five-part__text-one {
   font-weight: 400;
   font-size: 1.8vw;
   line-height: 1.8vw;
}

.mvp-five-part__text-two {
   font-weight: 400;
   font-size: 1.2vw;
   line-height: 1.3vw;
}

.mvp-five-part__text-near-man {
   font-weight: 400;
   font-size: 2vw;
   line-height: 2vw;
}

.mvp-five-part__text-near-man-two {
   font-weight: 400;
   font-size: 2vw;
   line-height: 2vw;
}

.mvp-five-part__man-img {
   padding-left: 90px;
   // transform: scale(100%);
   display: flex;
   justify-content: flex-end;
}

.mvp-five-part__button {
   transform: scale(100%);
   & span {
      font-size: 15px;
      color: white;
   }

   & button {
      background: #3e54fc;
      color: white;
      font-size: 1.2vw;
      line-height: 1.3vw;
   }

   & .button_container {
      margin: 0;
      margin-top: 50px;
   }
}

.mvp-five-part__input {
   & input {
      color: #787878;
      border: 0;
      border-bottom: 1px solid #1e56e1;
      /* border-bottom: 1px solid #1e56e1; */
      height: 40px;
      width: 305px;
      background-color: inherit;
      /* background-image: url(Input-image.png); */
      background-position: right 0% bottom 50%;
      background-repeat: no-repeat;
   }

   & input:focus {
      outline: none;
   }
   & button {
      & svg {
         position: absolute;
         left: -30px;
         top: -12px;
         cursor: pointer;
      }
   }
}

.mvp-five-part__bottom-part {
   margin: auto;
   & input {
      display: none;
   }
   & button {
      display: none;
   }

   & .right-side-text {
      display: none;
   }

   & .privacy-policy {
      margin-top: 130px;
   }
}

@media (max-width: 1024px) {
   .mvp-five-part__bottom-part {
      margin-top: 20px !important;
      margin-bottom: -50px !important;

      & .copywrite {
         margin: 0;
      }
   }
   .mvp-five-part__text-near-man {
      font-size: 26px !important;
      line-height: 29px !important;
   }
   .mvp-five-part__text-near-man-two {
      margin-top: 40px !important;
      font-size: 26px !important;
      line-height: 29px !important;
   }
   .mvp-five-part__bottom-part{
      & .copywrite {
         display: none;
      }
   }
}

@media (max-width: 768px) {
   .man-in-hat__top-image {
      // top: 120px !important;
      & path {
         // transform: scale(0.5);
         // left: -90px !important;
      }
   }

   .mvp-five-part__input {
      & input {
         width: 90% !important;
         margin-bottom: 8px;
      }
   }
   .mvp-five-part__input button svg {
      left: -10px !important;
   }
   .mvp-five-part__kula-app {
      transform: scale(1.8);
      padding-right: 50px !important;
   }
   .mvp-five-part__text {
      margin-top: 50px;
      font-size: 45px !important;
      line-height: 29px !important;
   }
   .mvp-five-part__text-one {
      font-size: 25px !important;
      line-height: 29px !important;
      margin-top: 30px !important;
   }
   .mvp-five-part__text-two {
      font-weight: 300;
      font-size: 18px !important;
      line-height: 25px !important;
      margin-top: 30px !important;
   }

   .mvp-five-part__man-part {
      margin-top: -50px !important;
      height: 500px;
   }
   .mvp-five-part__man-img {
      padding: 0 !important;
      // max-width: 187px !important;
      .parallax-img {
         height: 300px;
      }
      // margin-top: 50px !important;
      & svg {
         width: 300px;
         // transform: scale(1.3);
      }
   }

   .mvp-five-part__button {
      margin-top: 10px !important;
   }

   .mvp-five-part__bottom-part {
      margin-top: 120px !important;
      margin-bottom: -50px !important;

      & .copywrite {
         margin: 0;
      }
   }
}
