/* 
.main-img-left {
left: 548px
}

.main-img-center{
    left: 764px;
    top: 452px;
} */

.main-img {
   /* margin-top: 115px; */
   position: relative;
   display: block;
   margin-left: -30px;
   margin: auto;

   max-height: 606px;
   max-width: 824px;
}

.main-image__full {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;

   /* 
   display: block;
   margin: auto; */
}
.main-image__right {
   position: absolute;
   resize: both;
   right: 20px;
   max-width: 315px;
   max-height: 347px;
}
.main-image__right svg {
   position: relative;
   width: 100%;

}

.main-image__bottom-right {
   position: absolute;
   resize: both;
   max-width: 231px;
   max-height: 357px;
   right: 20px;
   bottom: 40px


}
.main-image__bottom-right svg {
   position: relative;
   width: 100%;

}

.main-image__center {
   position: absolute;
   max-width: 307px;
   max-height: 358px;
   resize: both;
   left: 80px;

}
.main-image__center svg {
   position: relative;
   width: 100%;
}

.main-image__bottom {
   position: absolute;
   max-width: 307px;
   max-height: 358px;
   resize: both;
   margin: auto;
   left: 80%;
}
.main-image__bottom svg {
   position: relative;

   width: 100%;
}

.main-image__left {
    position: absolute;
   max-width: 304px;
   max-height: 507px;
   resize: both;
   left: 80%;

}
.main-image__left-dop{
    position: relative;
    top: 390px;
    left: -36px;
}


@media (max-width: 420px ){
   .main-img{
      display: block;
      margin-left: auto;
      margin-right: auto;
      transform: scale(1.5,1.5);
      margin-top: 300px;
   }
}