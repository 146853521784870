.button_container_confirm {
   position: relative;
 

   margin: auto;

}
.button_su_confirm {
   overflow: hidden;
   position: relative;
   display: inline-block;
   margin-top: 45px;
   height: 46px;
   width: 100%;
   // border: 1.5px solid #1e56e1;
   background: #1e56e1;
   // border-radius: 3px;
}

.su_button_circle_confirm {
   background-color: red;
   border-radius: 1000px;
   position: absolute;
   left: 0;
   top: 0;
   width: 0px;
   height: 0px;

   margin-top: 0px;
   pointer-events: none;
   /*animation-timing-function: ease-in-out; */
}

.button_su_inner_confirm {
   display: inline-block;
   background-color: inherit;
   color: #1e56e1;
   border: none;
   font-size: 14px;
   font-weight: normal;
   width: 100%;
   height: 100%;

   text-align: center;
   // border-radius: 3px;
   transition: 400ms;
   text-decoration: none;
   //   padding: 22px;

   display: table-cell;
   vertical-align: middle;
   &:hover, &:hover span{
    cursor: pointer;
    transition: ease all 1.0s;
    border: 1.5px solid #000000;
    color: black;
   }
}



.explode-circle {
   animation: explode 1s forwards;
}

.desplode-circle {
   animation: desplode 0.5s forwards;
}

@keyframes explode {
   0% {
      width: 0px;
      height: 0px;
      margin-left: 0px;
      margin-top: 0px;
      background-color: rgba(42, 53, 80, 0.2);
   }
   100% {
      width: 1000px;
      height: 800px;
      margin-left: -480px;
      margin-top: -200px;
      background-color: rgba(255, 180, 10, 1);
   }
}

@keyframes desplode {
   0% {
      width: 1000px;
      height: 800px;
      margin-left: -480px;
      margin-top: -200px;
      background-color: rgba(255, 180, 10, 1);
   }
   100% {
      width: 0px;
      height: 0px;
      margin-left: 0px;
      margin-top: 0px;
      background-color: rgba(255, 180, 10, 1);
   }
}
